document.addEventListener("turbolinks:load", function() {
	$( document ).on( "click","[data-contact-flag]", function() {
		isDisabled = $( this ).prop( 'checked' );
		if( isDisabled ) {
			$( "#akoe_request_e_address" ).removeAttr( "disabled" );
			$( "#akoe_request_comments" ).removeAttr( "disabled" );
		} else {
			$( "#akoe_request_e_address" ).val( '' );
			$( "#akoe_request_e_address" ).prop( 'disabled', true );
			$( "#akoe_request_comments" ).empty();
			$( "#akoe_request_comments" ).prop( 'disabled', true );
		}
	});
})
